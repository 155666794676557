import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/core/services/store';

import {VERIFY_AUTH} from "@/core/services/store/auth.module";
import {RESET_LAYOUT_CONFIG} from "@/core/services/store/config.module";

import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";

window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";

window.ClipboardJS = ClipboardJS;

import "@/core/plugins/portal-vue";
import '@/core/plugins/bootstrap-vue';
import "@/core/plugins/perfect-scrollbar";
import '@/core/plugins/inline-svg';
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@/core/plugins/apexcharts";

// loader plugin
import Loader from '@/core/plugins/loader';

Vue.component('loading', Loader);

import Notify from "@/core/plugins/notify";

Vue.use(Notify);
// import Layout from "@/core/plugins/layout";
// Vue.use(Layout);

import Multiselect from 'vue-multiselect';

Vue.component('multiselect', Multiselect, {
	deselectLabel: 'x',
	selectLabel: 'v'
});
import 'vue-multiselect/dist/vue-multiselect.min.css';

import vSelect from 'vue-select';

Vue.component('v-select', vSelect);
import 'vue-select/dist/vue-select.css';


// import '@desislavsd/vue-select/dist/vue-select.css'
// import VueSelect from '@desislavsd/vue-select'
// Vue.use(VueSelect, { /* options */ } )


import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import moment2 from 'moment';

Vue.use(VueMoment, {moment});

import VModal from 'vue-js-modal'

Vue.use(VModal, {
	dynamic: true,
	injectModalsContainer: true,
	height: '90%'
});

import Paginate from '@/views/components/Paginate';
Vue.component('paginate', Paginate);

import QuickEdit from '@/views/components/QuickEdit';
Vue.component('quick-edit', QuickEdit);

import QuickEditSelect from '@/views/components/QuickEditSelect';
Vue.component('quick-edit-select', QuickEditSelect);
// sort-column component
import SortColumn from '@/views/components/SortColumn';

Vue.component('dw-th', SortColumn);

import DateRangePicker from 'vue2-daterange-picker';

Vue.component('date-range-picker', DateRangePicker);
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

Vue.filter('formatDate', function (value) {
	console.log('fd value', value);
	if (value) {
		return moment2(String(value)).format('MM/DD/YYYY')
	}
});

// import datePicker from 'vue-bootstrap-datetimepicker';
// import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
// Vue.use(datePicker);
// datepicker component
import DwDateTimePicker from '@/views/components/DwDateTimePicker';

Vue.component('dw-date-time-picker', DwDateTimePicker);


Vue.filter('capitalize', function (value) {
	if (!value) return ''
	value = value.toString()
	return value.charAt(0).toUpperCase() + value.slice(1)
});
Vue.filter('countSelected', function (value) {
	if (!value) return '';
	return (value.filter(i => i._selected).length) + ' / ' + value.length;
});


import Entity from '@/views/components/Entity';
Vue.component('entity', Entity);

import ApiService from "@/core/services/api.service";

ApiService.init();
import "@/core/services/data.service";

import vueDebounce from 'vue-debounce';
import {REMOVE_BODY_CLASSNAME} from "@/core/services/store/htmlclass.module";

Vue.use(vueDebounce, {
	defaultTime: '500ms',
	listenTo: 'oninput'
});

router.beforeEach((to, from, next) => {

	// Ensure we checked auth before each page load.
	Promise.all([store.dispatch(VERIFY_AUTH)]).then(function (resp) {
		let isAuthenticated = resp[0];
		if (to.name !== 'login') {  // dashboard
			if (!isAuthenticated) {
				next({
					name: 'login'
				});
			} else {
				next();
			}
		} else {
			if (isAuthenticated) {  // logged in
				next({
					name: 'dashboard'
				});
			} else {
				next();
			}
		}
	});

	// reset config to initial state
	store.dispatch(RESET_LAYOUT_CONFIG);
	// console.log('CLEAR_PAGE_BUTTONS ---');
	// store.dispatch(CLEAR_PAGE_BUTTONS);

	// Scroll page to top on every route change
	setTimeout(() => {
		window.scrollTo(0, 0);
	}, 100);
});

let instance = new Vue({
	router,
	store,
	render: h => h(App)
}).$mount("#app");
Notify._instance = instance;

