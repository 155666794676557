<template lang="pug">
	span(v-if="value")
		.form-group
			label Dates
			date-range-picker(:locale-data="datePickerOptions.locale", :ranges="datePickerOptions.ranges",
				:auto-apply="true", v-model="range", :time-picker="true", :time-picker24-hour="true",
				:time-picker-increment="1", @update="updateValues")
		.form-group
			label Timezone
			select.form-control(v-model="timezone", @input="updateValues")
				option(v-for="t in timeZoneOptions", :value="t.v") {{ t.t }}
</template>
<script>
	import Vue from 'vue';
	import moment from 'moment';

	export default {
		name: 'DwDateTimePicker',
		props: ['value'],
		data() {
			return {
				timezone: 0,
				range: {
					startDate: null,
					endDate: null
				},
				timeZoneOptions: Vue.$dwData.general.timezoneOptions,
				datePickerOptions: {
					timePicker: true,
					timePicker24Hour: true,
					locale: {
						format: 'DD/MM/YYYY HH:mm'
					},
					alwaysShowCalendars: true,
					autoApply: true,
					ranges: {}
				}
			};
		},
		methods: {
			updateValues(){
				setTimeout(() => {
					this.$emit('input', {timezone: this.timezone, dateRange: this.range});
				})
			},
			updateRanges(){
				// let tz = this.value ? (this.value.timezone || 0) : 0;
				let tz = this.timezone;
				let localOffset = new Date().getTimezoneOffset() / 60;
				let tzGap = (-localOffset) - tz;

				this.datePickerOptions.ranges = {
					'This Hour': [
						moment(moment().subtract(tzGap, 'hours').format('DD/MM/YYYY HH') + ':00', 'DD/MM/YYYY HH:mm'),
						moment(moment().subtract(tzGap, 'hours').format('DD/MM/YYYY HH') + ':59', 'DD/MM/YYYY HH:mm'),
					],
					'Last Hour': [
						moment(moment().subtract(tzGap, 'hours').subtract(1, 'hours').format('DD/MM/YYYY HH') + ':00', 'DD/MM/YYYY HH:mm'),
						moment(moment().subtract(tzGap, 'hours').subtract(1, 'hours').format('DD/MM/YYYY HH') + ':59', 'DD/MM/YYYY HH:mm'),
					],
					'Today': [
						moment(moment().subtract(tzGap, 'hours').format('DD/MM/YYYY') + ' 00:00', 'DD/MM/YYYY HH:mm'),
						moment(moment().subtract(tzGap, 'hours').format('DD/MM/YYYY') + ' 23:59', 'DD/MM/YYYY HH:mm'),
					],
					'Yesterday': [
						moment(moment().subtract(tzGap, 'hours').subtract(1, 'days').format('DD/MM/YYYY') + ' 00:00', 'DD/MM/YYYY HH:mm'),
						moment(moment().subtract(tzGap, 'hours').subtract(1, 'days').format('DD/MM/YYYY') + ' 23:59', 'DD/MM/YYYY HH:mm')
					],
					'Last 7 Days': [
						moment(moment().subtract(tzGap, 'hours').subtract(6, 'days').format('DD/MM/YYYY') + ' 00:00', 'DD/MM/YYYY HH:mm'),
						moment(moment().subtract(tzGap, 'hours').format('DD/MM/YYYY') + ' 23:59', 'DD/MM/YYYY HH:mm')
					],
					'Last 30 Days': [
						moment(moment().subtract(tzGap, 'hours').subtract(29, 'days').format('DD/MM/YYYY') + ' 00:00', 'DD/MM/YYYY HH:mm'),
						moment(moment().subtract(tzGap, 'hours').format('DD/MM/YYYY') + ' 23:59', 'DD/MM/YYYY HH:mm')
					],
					'This Month': [
						moment(moment().subtract(tzGap, 'hours').startOf('month').format('DD/MM/YYYY') + ' 00:00', 'DD/MM/YYYY HH:mm'),
						moment(moment().subtract(tzGap, 'hours').endOf('month').format('DD/MM/YYYY') + ' 23:59', 'DD/MM/YYYY HH:mm')
					],
					'Last Month': [
						moment(moment().subtract(tzGap, 'hours').subtract(1, 'month').startOf('month').format('DD/MM/YYYY') + ' 00:00', 'DD/MM/YYYY HH:mm'),
						moment(moment().subtract(tzGap, 'hours').subtract(1, 'month').endOf('month').format('DD/MM/YYYY') + ' 23:59', 'DD/MM/YYYY HH:mm')
					]
				};
			},
		},
		mounted(){
			if (!this.value.dateRange.startDate){
				this.range.startDate = moment(moment().subtract(-(new Date().getTimezoneOffset() / 60), 'hours').format('DD/MM/YYYY') + ' 00:00', 'DD/MM/YYYY HH:mm');
			} else {
				this.range.startDate = this.value.dateRange.startDate;
			}
			if (!this.value.dateRange.endDate){
				this.range.endDate = moment(moment().subtract(-(new Date().getTimezoneOffset() / 60), 'hours').format('DD/MM/YYYY') + ' 23:59', 'DD/MM/YYYY HH:mm');
			} else {
				this.range.endDate = this.value.dateRange.endDate;
			}
			this.timezone = this.value.timezone || 0;
			this.updateRanges();
			let intv = setInterval(() => {
				this.updateRanges();
			}, 60 * 1000);
			this.$once('hook:beforeDestroy', () => {
				clearInterval(intv);
			});
			this.updateValues(this.range);
		}
	}
</script>
