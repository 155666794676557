<template lang="pug">
	div.entity(:class="'entity-'+type + ' mode-'+mode" :style="wrapperStyle")
		div.active-area(@click="entityClicked()")
			span.id {{ id }}
			span.name {{ name }}
		ul.quick-menu
			li(v-for="b in buttons")
				a(v-if="b.action", @click="b.action()", v-b-tooltip.hover.bottom, :title="b.label" )
					i.la(:class="'la-'+b.icon")
				router-link(v-if="b.link", :to="b.link", v-b-tooltip.hover.bottom, :title="b.label")
					i.la(:class="'la-'+b.icon")

</template>
<style lang="scss">

$color-mmp_account: rgb(128, 178, 195);

.entity {
	display: inline-block;
	padding: 3px 5px;
	border-radius: 5px;
	position: relative;
	transition: all .3s ease-in-out;
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 12px;
	line-height: 14px;
	background: rgba(0, 0, 0, 0.1);

	&.no-bg {
		background: transparent !important;

		&:hover {
			background: rgba(0, 0, 0, 0.1) !important;
		}
	}

	&:hover {
		background: rgba(0, 0, 0, 0.2);
	}

	span.name {
		/*max-width: 120px;*/
		overflow: hidden;
		display: inline;
		text-overflow: ellipsis;
		word-break: break-all;
	}


	span.id {
		//font-size: 12px;
		//position: absolute;
		//left: 5px;
		//top: -6px;
		border-radius: 3px;
		padding: 2px 2px 1px 2px;
		margin-right: 2px;
		margin-left: -5px;

		vertical-align: top;
		background: rgba(0, 0, 0, 0.2);
		color: #fff;
	}

	&.mode-mini {

		width: 75px;

		.active-area {
			//display: flex;
			position: relative;

			span.id {

			}

			span.name {
				top: 0;
				left: 100%;
				margin-left: 5px;
				z-index: 20;
				background: #fff;
				padding: 2px;
				border-radius: 5px;
				//display: none;
				position: absolute;
				display: none;
				width: 150px;
				//flex: 1;
				overflow: hidden;
				//display: inline-block;
			}

			&:hover {
				span.name {
					display: block;
				}
			}
		}

	}


	&.entity-mmp_account {
		background: rgba($color-mmp_account, 0.2);

		&:hover {
			background: $color-mmp_account;
		}

		&.no-bg {
			&:hover {
				background: rgba($color-mmp_account, 0.1) !important;
			}
		}

		span.id {
			background: $color-mmp_account;
			color: #fff;
		}

		span.name {
			max-width: 100%;
			overflow: hidden;
			display: inline-block;
			text-overflow: ellipsis;
		}

		ul.quick-menu li a {
			background: $color-mmp_account;
		}
	}

	ul.quick-menu {
		display: none;
		//box-shadow: 2px 2px 3px rgba(0, 0, 0, .5);
		list-style: none;
		position: absolute;
		padding: 0;
		margin: 0;
		border-radius: 0 0 5px 5px;
		overflow: hidden;
		right: 0;
		bottom: -24px;
		height: 24px;
		z-index: 10;

		li {
			list-style: none;
			display: inline-block;
			float: right;
			width: 24px;
			height: 24px;

			a {
				//opacity: .8;
				color: #fff;
				cursor: pointer;
				float: right;
				width: 24px;
				height: 24px;
				line-height: 24px;
				text-align: center;
				font-size: 12px;
				transition: all .3s ease-in-out;
				i {
					color: #fff;
				}
				&:hover {
					//opacity: 1;
				}
			}
		}
	}

	&:hover {
		border-radius: 5px 5px 0 5px;

		ul.quick-menu {
			display: block;
		}
	}
}
</style>
<script>
import Vue from 'vue';

export default {
	name: 'Entity',
	props: {
		id: [String, Number],
		name: [String, Number],
		mode: String,
		type: String,
		maxWidth: Number,
		data: Object
	},
	data(){
		return {
			//buttons: [],
			ctimeout: null,
			clickCounter: 0
		};
	},
	methods: {
		entityClicked(){
			this.clickCounter++;
			if (this.clickCounter === 1) {
				this.ctimeout = setTimeout(() => {
					this.clickCounter = 0;
					this.$emit('click');
				}, 400);
			} else {
				clearTimeout(this.ctimeout);
				this.clickCounter = 0;
				this.$emit('dblclick');
			}
		}
	},
	computed: {
		wrapperStyle(){
			let o = {};
			if (this.maxWidth) {
				o['max-width'] = this.maxWidth + 'px';
			}
			return o;
		},
		buttons(){
			let entity = this;
			switch (this.type) {
				case 'mmp_account': {
					return [
						{
							icon: 'search',
							label: 'Edit',
							link: {
								name: 'mmp-account-edit',
								params: {id: this.id}
							}
						},
					];
				}
			}
			return [];
		}
	},
}
</script>
