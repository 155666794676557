import Vue from 'vue';

const DataService = {

	install(Vue/*, options*/) {

		let api = {

			common: {
				getSelectedFromString: function (options, str) {
					let selected = [];
					if (str) {
						str += '';
						let itemList = str.split(',').map(r => +r).filter(r => r);
						options.forEach(o => {
							if (itemList.indexOf(o.v) > -1) {
								selected.push(o);
							}
						});
					}
					return selected;
				}
			},

			general: {
				mmpOptions: [
					{v: 1, t: 'AppsFlyer'},
					{v: 2, t: 'Adjust'},
					{v: 3, t: 'Branch'},
					{v: 4, t: 'Kochava'},
					{v: 5, t: 'Singular'},
					//{v: 'tenjin', t: 'Tenjin'},
					//{v: 'tune', t: 'Tune'},
				],
				platformOptions: [
					{v: 'android', t: 'android'},
					{v: 'ios', t: 'ios'},
					{v: 'web', t: 'web'},
				],
				currencyOptions: [
					{v: 'USD', t: 'USD'},
					{v: '%', t: '%'},
				],

				timezoneOptions: [
					{v: -9, t: '-09:00'},
					{v: -8, t: '-08:00'},
					{v: -7, t: '-07:00'},
					{v: -6, t: '-06:00'},
					{v: -5, t: '-05:00'},
					{v: -4, t: '-04:00'},
					{v: -3, t: '-03:00'},
					{v: -2, t: '-02:00'},
					{v: -1, t: '-01:00'},
					{v: 0, t: '+00:00 UTC'},
					{v: 1, t: '+01:00'},
					{v: 2, t: '+02:00'},
					{v: 3, t: '+03:00'},
					{v: 4, t: '+04:00'},
					{v: 5, t: '+05:00'},
					{v: 6, t: '+06:00'},
					{v: 7, t: '+07:00'},
					{v: 8, t: '+08:00'},
					{v: 9, t: '+09:00'},
				],
				payoutModelOptions: [{v: 'CPA'}, {v: 'CPI'}, {v: 'CPR'}, {v: 'CPC'}, {v: 'CPM'}, {v: 'CPS'}],
				typeOptions: [
					{v: 'agency', t: 'Agency'},
					{v: 'adnetwork', t: 'AdNetwork'},
				],
				reportTypeOptions: [
					{v: 'installs_report', t: 'Installs' },
					{v: 'in_app_events_report', t: 'In-App Events' },
					{v: 'blocked_installs_report', t: 'Blocked Installs' },
					{v: 'blocked_in_app_events_report', t: 'Blocked In-App Events' },
					{v: 'detection', t: 'Detection (Post Attr)' },
					{v: 'fraud-post-inapps', t: 'Fraud Post In-App' },
					{v: 'blocked_clicks_report', t: 'Blocked Clicks' },
				],
				aggregatedReportTypeOptions: [
					{v: 'geo_by_date_report', t: 'Geo By Date' },
				],

			},

			country: {
				_options: [],
				_hash: {},
				async getOptions(reload) {
					if (reload || this._options.length === 0) {
						let resp = await Vue.$api.get('geo/getCountries', {params: {page_size: 10000}});
						this._options = resp.records;
					}
					return this._options;
				}
			},
			region: {
				_options: [],
				_hash: {},
				async getOptions(reload, country = null) {
					if (reload || this._options.length === 0) {
						let resp = country ? await Vue.$api.get('geo/getRegions', {
							params: {
								page_size: 10000,
								country
							}
						}) : await Vue.$api.get('geo/getRegions', {params: {page_size: 10000}});
						this._options = resp.records;
					}
					return this._options;
				},
				async getOptionsByCountry(countries) {
					try {
						let resp = await Vue.$api.get('geo/getRegions', {params: countries});
						return resp.records;

					} catch (e) {
						throw e
					}
				}
			},

			city: {
				_options: [],
				_hash: {},
				async getOptions(reload, country = null) {
					if (reload || this._options.length === 0) {
						let resp = country ? await Vue.$api.get('geo/getCities', {
							params: {
								page_size: 10000,
								country
							}
						}) : await Vue.$api.get('geo/getCities', {params: {page_size: 10000}});
						this._options = resp.records;
					}
					return this._options;
				},
				async getOptionsByCountry(countries) {
					try {
						let resp = await Vue.$api.get('geo/getCities', {params: countries});
						return resp.records;
					} catch (e) {
						throw e
					}
				}
			},

			network: {
				_options: [],
				_hash: {},
				async getNetworks(reload) {
					if (reload || this._options.length === 0) {
						let resp = await Vue.$api.get('/getAll', {params: {page_size: 10000}});
						this._options = resp.records;
					}
					return this._options;
				},
			},

			user: {
				_booleans: [],
				_options: [],
				_hash: {},
				newInstance() {
					return {
						id: 0,
						network_id: 0,
						first_name: '',
						last_name: '',
					};
				},
				async get(id, reload) {
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('users/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(reload) {
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('users/getOptions');
						this._options = resp.options;
					}
					return this._options;
				}
			},
			asset: {
				_booleans: [],
				_options: [],
				_hash: {},
				newInstance() {
					return {
						id: 0,
						name: '',
						package_id: '',
					};
				},
				async get(id, reload) {
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('assets/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(reload) {
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('assets/getOptions');
						this._options = resp.options;
					}
					return this._options;
				}
			},
			mmpAccount: {
				_booleans: [],
				_options: [],
				_hash: {},
				newInstance() {
					return {
						id: 0,
						type: '',
						name: '',
						api_token: '',
						credentials: {},
						assets: []
					};
				},
				async getOptions(reload) {
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('mmp-accounts/getOptions');
						this._options = resp.options;
					}
					return this._options;
				},
				async get(id, reload) {
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('mmp-accounts/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async delete(id) {
					let resp = await Vue.$api.delete('mmp-accounts/delete/' + id);
					return resp;
				},
				async getIntegrations(){
					let resp = await Vue.$api.get('mmp-accounts/getIntegrations');
					return resp.integrations;
				}
			},
			reportFetchJob: {
				statusOptions: [
					{v: 'draft', t: 'Draft'},
					{v: 'pending', t: 'Pending'},
					{v: 'complete', t: 'Complete'},
					{v: 'error', t: 'Error'},
				],
				async delete(id) {
					let resp = await Vue.$api.delete('report-fetch-jobs/delete/' + id);
					return resp;
				},
			}

		};


		Vue.$dwData = api;
		Vue.prototype.$dwData = api;
	}

};


Vue.use(DataService);

export default DataService;
